
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { string } from 'yup/lib/locale';
import moment from 'moment';

export default defineComponent({
	name: 'add-enumeration-modal',
	props: {
        ContentType: String,
        ElementId: String,
	},
	components: {},
	setup(props, { emit }) {
		const formRef = ref<null | HTMLFormElement>(null);
		const addEnumerationModalRef = ref<null | HTMLElement>(null);
		const loading = ref<boolean>(false);
		const formData = ref({
			title: '',
			dateRange: '',
            eventContentType: props.ContentType,
            elementId: props.ElementId,
		});

		const defaultTime = new Date(2000, 1, 1, 12, 0, 0)

		const rules = ref({
			title: [
				{
					required: true,
					message: 'Title is required',
					trigger: 'blur'
				}
			],
			dateRange: [
				{
					required: true,
					message: 'Start and end date is required',
					trigger: 'blur'
				}
			]
		});
		const disabledDate = (time: Date) => {
			let today = new Date().setHours(0, 0, 0, 0)
  			return time.getTime() < today
		}
		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;
					const data: any = {
						title: formData.value.title,
						startDate: moment(new Date(formData.value?.dateRange[0])).format('MM/DD/YYYY, hh:mm:ss A Z'),
						endDate: moment(new Date(formData.value?.dateRange[1])).format('MM/DD/YYYY, hh:mm:ss A Z'),
						eventContentType: formData.value.eventContentType,
						elementId: formData.value.elementId,
					};
					console.log(formData.value)
					ApiService.post('ScheduleEvents', data)
						.then(({ data }) => {
							console.log('create Schedule event response', data);
							loading.value = false;
							emit('getScheduleEventsList');
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								hideModal(addEnumerationModalRef.value);
								formData.value.dateRange = '';
								(document.getElementById('formRef') as HTMLFormElement).reset();
							});
						})
						.catch(({ response }) => {
							console.log('create Schedule event error', response);
							Swal.fire({
								text: `${response.data?.message}`,
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			formData,
			rules,
			submit,
			formRef,
			loading,
			defaultTime,
		    disabledDate,
			addEnumerationModalRef
		};
	}
});
