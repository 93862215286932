
import { defineComponent, PropType, ref, computed } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { string } from 'yup/lib/locale';
import { IScheduleEvent } from '@/core/data/scheduleEvent';
import moment from 'moment';

export default defineComponent({
	name: 'edit-enumeration-modal',
	props: {
        ScheduleEvent:
		{
			type: Object,
			default: () => ({
				id: '',
				title: '',
				dateRange: [''],
				eventContentType: '',
				elementId: ''
				}
			)
		}
	},

	components: {},
	setup(props, { emit }) {
		const formRef = ref<null | HTMLFormElement>(null);
		const editEnumerationModalRef = ref<null | HTMLElement>(null);
		const loading = ref<boolean>(false);
		const formData = computed(() => {
			return props.ScheduleEvent;
		});
		const defaultTime = new Date(2000, 1, 1, 12, 0, 0)

		const rules = ref({
			title: [
				{
					required: true,
					message: 'Title is required',
					trigger: 'change'
				}
			],
			dateRange: [
				{
					required: true,
					message: 'Start and end date is required',
					trigger: 'change'
				}
			]
		});

		const disabledDate = (time: Date) => {
			let today = new Date().setHours(0, 0, 0, 0)
  			return time.getTime() < today
		}

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;
					console.log("data range", formData.value?.dateRange)
					const data: any = {
						title: formData.value?.title,
						startDate: moment(new Date(formData.value?.dateRange[0])).format('MM/DD/YYYY, hh:mm:ss A Z'),
						endDate: moment(new Date(formData.value?.dateRange[1])).format('MM/DD/YYYY, hh:mm:ss A Z'),
						eventContentType: formData.value?.eventContentType,
						elementId: formData.value?.elementId,
					};
					console.log("Data for request", data)
					ApiService.put(`ScheduleEvents/${props.ScheduleEvent?.id}`, data)
						.then(({ data }) => {
							console.log('edit Schedule event response', data);
							loading.value = false;
							emit('getScheduleEventsList');
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								hideModal(editEnumerationModalRef.value);
								(document.getElementById('formRef') as HTMLFormElement).reset();
							});
						})
						.catch(({ response }) => {
							console.log('edit Schedule event error', response);
							Swal.fire({
								text: `${response.data?.message}`,
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			formData,
			rules,
			submit,
			formRef,
			loading,
			defaultTime,
		    disabledDate,
			editEnumerationModalRef
		};
	}
});
