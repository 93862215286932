
import { defineComponent, onMounted, ref, computed } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import Swal from 'sweetalert2/dist/sweetalert2.js';
// @import utils
import { IUserLevel } from '@/core/data/userLevel';
import { IScheduleEvent } from '@/core/data/scheduleEvent';
import ApiService from '@/core/services/ApiService';
import { IEnumerationTypes } from '@/core/data/enumerationTypes';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
// @import components
import AddVideoAsset from '@/components/modals/forms/AddVideoAsset.vue';
import PlayListModal from '@/components/modals/forms/PlayListModal.vue';
import PricePlanModal from '@/components/modals/forms/PricePlanModel.vue';
import AddScheduleEventModal from '@/components/modals/forms/AddScheduleEventModal.vue';
import EditScheduleEventModal from '@/components/modals/forms/EditScheduleEventModal.vue';
import RelatedContent from '@/components/related-content/RelatedContent.vue';
import CMSImageListModel from '@/components/modals/forms/CMSImageListModel.vue';
import VideoContentModal from '@/components/modals/forms/VideoContentModal.vue';
import TemplateContentModal from '@/components/modals/forms/TemplateContentModal.vue';

export default defineComponent({
  name: 'Dynamic-template-content',
  components: {
    QuillEditor,
    AddVideoAsset,
    PlayListModal,
    RelatedContent,
    PricePlanModal,
    CMSImageListModel,
    VideoContentModal,
    TemplateContentModal,
    AddScheduleEventModal,
    EditScheduleEventModal
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const imageModel = ref<any>(null);
    const descriptionEditor = ref<any>(null);
    const headerIntroTextEditor = ref<any>(null);
    const overviewShortDescriptionEditor = ref<any>(null);
    const content1Editor = ref<any>(null);
    const content2Editor = ref<any>(null);
    const content3Editor = ref<any>(null);
    const content4Editor = ref<any>(null);
    const formRef = ref<null | HTMLFormElement>(null);

    const templateId = ref(route.params.id)
    const contentId = ref(route.params.contentId)
    const imageKey = ref('');
    const videoKey = ref('headerHighlightVideo');
    const enumerationsType = ref<Array<IEnumerationTypes>>([]);
    const videoAssetObj = ref<any>({});
    const cmsListObj = ref<any>({});
    const enumerationListObj = ref<any>({});
    const loading = ref<boolean>(false);
    const detailImageUrl = ref<string>('');
    const relatedPlayListObj = ref<any>({});
    const selectedPricingPlans = ref<any>([]);
    const extraVideoAssetsArray = ref<any>([]);
    const extraVideoAssetsIdArray = ref<any>([]);
    const userLevel = ref<Array<IUserLevel>>([]);
    const scheduleEvents = ref<Array<IScheduleEvent>>([]);

    const relatedContentsExpanded = ref<any>([]);

    var sample: {
          id: string,
          title: string,
          dateRange: string[],
          eventContentType: string,
          elementId: string
		} = {
          id: '',
          title: '',
          dateRange: [''],
          eventContentType: '',
          elementId: ''
		}
    const selectedScheduledEvent = ref(sample);

    const isEdit = route.params.id;
    const contentEdit = route.params.contentId;
    const isContentPage = route.fullPath.includes('templatecontent');

    const formData = ref({
      id: '',
      published: true,
      public: true,
      userLevels: [],
      pricingPlans: '',
      title: '',
      freeContent: true,
      customerId: 0,
      creatorId: 0,
      enumerationId: '',
			enumerationValueId: '',
			playList1: '',
			playList2: '',
			playList3: '',
			contentImage1: '',
			contentImage2: '',
			contentImage3: '',
			content1: '',
			content2: '',
			content3: '',
			created: '',
			updated: '',

      publicationDate: '',
      sortOrder: '',
      slug: '',
      displayTitle: '',
      overviewHeaderTitle: '',
      overviewShortDescription: '',
      overviewButtonLabel: '',
      overviewButtonUrl: '',
      overviewImage: '',
      header: '',
      headerImage: '',
      headerHighlightVideo: '',
      headerTitle: '',
      headerSubtitle: '',
      headerIntroText: '',
      headerButtonLabel: '',
      headerButtonUrl: '',
      headerSecondButtonLabel: '',
      headerSecondButtonUrl: '',
      content: '',
      contentFirst: '',
      contentSecond: '',
      contentThird: '',
      contentFourth: '',
      media: '',
      mediaFirstImage: '',
      mediaSecondImage: '',
      mediaThirdImage: '',
      mediaFourthImage: '',
      relatedPlayList: '',
      relatedContents: []
    });

    const templateData = ref({
      contentName: '',
			enumerationId: '',
			contentType: 'Component',
      newsfeedSupport: false,
      displayTitle: false,
      slug: false,
      publicationDate: false,
      sortOrder: false,
      overview: false,
      overviewHeaderTitle: false,
      overviewShortDescription: false,
      overviewButtonLabel: false,
      overviewButtonUrl: false,
      overviewImage: false,
      header: false,
      headerImage: false,
      headerHighlightVideo: false,
      headerTitle: false,
      headerSubtitle: false,
      headerIntroText: false,
      headerButtonLabel: false,
      headerButtonUrl: false,
      headerSecondButtonLabel: false,
      headerSecondButtonUrl: false,
      content: false,
      contentFirst: false,
      contentSecond: false,
      contentThird: false,
      contentFourth: false,
      media: false,
      mediaFirstImage: false,
      mediaSecondImage: false,
      mediaThirdImage: false,
      mediaFourthImage: false,
      relatedPlaylist: false,
      extraVideoAssets: false,
      relatedContent: false
    });

    const fieldsData = ref({
      label: '',
      url: '',
      graden: '',
      themas: '',
      genre: '',
      country: '',
      duration: '',
      dialog: '',
      subtitle: '',
      director: '',
      year: '',
      festival: '',
      rating: '',
      degrees: '',
      tag: '',
      theme: '',
      blogauthor: '',
      blogtype: '',
      cast: '',
      journeys: '',
      level: '',
      recipies: '',
      role: '',
      teachers: '',
      yogastyles: '',
      yogatype: ''
    });

    const getvideoAssetDataById = (mId, key) => {
      ApiService.get(`VideoAsset/${mId}`)
        .then(({ data }) => {
          if (key === 'extraVideoAssets') {
            if (!extraVideoAssetsIdArray.value.includes(data.mediaId)) {
              extraVideoAssetsArray.value.push(data);
              extraVideoAssetsIdArray.value.push(data.mediaId);
            }
          } else {
            videoAssetObj.value = {
              ...videoAssetObj.value,
              [key]: data
            };
          }
        })
        .catch(({ response }) => {
          console.log('error', response);
        });
    };

    const getVideoContentById = mId => {
      console.log('id: ', mId);
      ApiService.get(`VideoContent/${mId}`)
        .then(({ data }) => {
          setSelectedVideoContent(data);
          setExtraVideoContent(data);
        })
        .catch(({ response }) => {
          console.log('error', response);
        });
    };

    const getVideoAssetData = playListData => {
      if (playListData.headerHighlightVideo) {
        getVideoContentById(playListData.headerHighlightVideo);
        getvideoAssetDataById(
          playListData.headerHighlightVideo,
          'headerHighlightVideo'
        );
      }
    };

    const getCMSListDataById = (mId, key) => {
      ApiService.get(`CmsMedia/${mId}`)
        .then(({ data }) => {
          cmsListObj.value = {
            ...cmsListObj.value,
            [key]: data.url
          };
        })
        .catch(({ response }) => {
          console.log('error', response);
        });
    };

    const getThumbnailData = data => {
      if (data.mediaFirstImage) {
        getCMSListDataById(data.mediaFirstImage, 'mediaFirstImage');
      }
      if (data.headerImage) {
        getCMSListDataById(data.headerImage, 'headerImage');
      }
      if (data.overviewImage) {
        getCMSListDataById(data.overviewImage, 'overviewImage');
      }
      if (data.mediaSecondImage) {
        getCMSListDataById(data.mediaSecondImage, 'mediaSecondImage');
      }
      if (data.mediaThirdImage) {
        getCMSListDataById(data.mediaThirdImage, 'mediaThirdImage');
      }
      if (data.mediaFourthImage) {
        getCMSListDataById(data.mediaFourthImage, 'mediaFourthImage');
      }
    };

    const getPageById = () => {
      ApiService.get(`Template/${route.params.id}`)
        .then(({ data }) => {
          if (data && data?.contentType) {
            setBreadcrumbs(data?.contentType, data?.contentName);
          }
          templateData.value = {
            ...templateData.value,
            ...data
          };
        })
        .catch(({ response }) => {
          console.log('error', response);
        });
    };

    const getPlayListDataById = mId => {
      ApiService.get(`PlayList/${mId}`)
        .then(({ data }) => {
          relatedPlayListObj.value = data;
        })
        .catch(({ response }) => {
          console.log('error', response);
        });
    };

    const getextraVideoAssets = extraData => {
      for (const item of extraData) {
        getVideoContentById(item);
      }
    };

    const getContentDetailById = () => {
      ApiService.get(`TemplateContent/${route.params.contentId}`)
        .then(({ data }) => {
          formData.value = {
            ...formData.value,
            ...data,
            ...data.templateContentFields,
            userLevels: data?.userLevels
              ? data.userLevels.indexOf(',') != -1 || data.userLevels != ''
                ? data.userLevels.split(',')
                : (data.userLevels = [])
              : []
          };

          if (data && data.relatedContents) {
            relatedContentsExpanded.value = data.relatedContentsExpanded;
          }

          if (data && data.additionalFields) {
            for (const tab of data.additionalFields) {
              for (const field of tab.fields) {
                if (field.fieldType === 'EnumMulti') {
                  const valArry = field.values
                    .filter(item => item.value)
                    .map(ele => ele.value);
                  fieldsData.value = {
                    ...fieldsData.value,
                    [field.fieldName]: valArry
                  };
                } else {
                  fieldsData.value = {
                    ...fieldsData.value,
                    [field.fieldName]: field.value
                  };
                }
              }
            }
          }

          getPricePlanList();
          if (data?.extraVideoAssets) {
            getextraVideoAssets(data?.extraVideoAssets);
          }
          if (data && data.relatedPlayList) {
            getPlayListDataById(data.relatedPlayList);
          }
          getScheduleEvents();
          if (data && data.templateContentFields) {
            const { templateContentFields } = data;
            getThumbnailData(templateContentFields);

            if (templateContentFields?.headerHighlightVideo) {
              getVideoContentById(templateContentFields.headerHighlightVideo);
            }

            if (templateContentFields?.headerIntroText) {
              headerIntroTextEditor.value.setHTML(
                templateContentFields?.headerIntroText || ''
              );
            }
            if (templateContentFields?.overviewShortDescription) {
              overviewShortDescriptionEditor.value.setHTML(
                templateContentFields?.overviewShortDescription || ''
              );
            }
            if (templateContentFields?.contentFirst) {
              content1Editor.value.setHTML(templateContentFields?.contentFirst);
            }
            if (templateContentFields?.contentSecond) {
              content2Editor.value.setHTML(templateContentFields.contentSecond);
            }
            if (templateContentFields?.contentThird) {
              content3Editor.value.setHTML(templateContentFields.contentThird);
            }
            if (templateContentFields?.contentFourth) {
              content4Editor.value.setHTML(templateContentFields.contentFourth);
            }
          }
        })
        .catch(({ response }) => {
          console.log('error', response);
        });
    };

    const getUserLevels = () => {
      ApiService.get('UserLevel')
        .then(({ data }) => {
          // console.log('UserLevel response: ', data);
          userLevel.value = data;
        })
        .catch(({ response }) => {
          console.log('response', response);
        });
    };

    const getEnumerationsType = templateID => {
      ApiService.get(`TabFields/TemplateContent/${templateID}`)
        .then(({ data }) => {
          enumerationsType.value = data;

          if (data && data.length) {
            for (const tab of data) {
              for (const field of tab.fields) {
                if (!fieldsData.value[field.jsonProperty]) {
                  fieldsData.value = {
                    ...fieldsData.value,
                    [field.jsonProperty]: ''
                  };
                }
              }
            }
          }
        })
        .catch(({ response }) => {
          console.log('response', response);
        });
    };

    const getEnumerationsList = () => {
      ApiService.get('EnumerationValues/Nested')
        .then(({ data }) => {
          // console.log('getEnumerationsList response: ', data);
          const filteredData = data.filter(item => {
            return item.customerId == selectedCustomer.value;
          });
          enumerationListObj.value = filteredData;
        })
        .catch(({ response }) => {
          console.log('getEnumerationsList error:', response);
        });
    };

    const getEnumValues = () => {
      if (enumerationListObj.value.length) {
        const enumerationValues = enumerationListObj.value.find(
          x => x.id == templateData.value.enumerationId
        )?.enumerationValues;
        return enumerationValues;
      }
    };

    const getEnumerationName = () => {
      if (enumerationListObj.value.length) {
        return enumerationListObj.value.find(
          x => x.id == templateData.value.enumerationId
        )?.enumName;
      }
    };

    const selectedCustomer = computed(() => {
      return store.getters.selectedCustomer;
    });

    const setBreadcrumbs = (contentType = '', contentName = '') => {
      if (isEdit) {
        if (isContentPage) {
          setCurrentPageBreadcrumbs(`${contentEdit ? 'Edit' : 'Add'}`, [
            'Template Content',
            `${
              contentType === 'Component' ? 'Component' : `Content`
            } - ${contentName}`,
            `${contentType === 'Component' ? 'Component' : isEdit}`
          ]);
        } else {
          setCurrentPageBreadcrumbs(
            `Edit ${contentType === 'Page' ? 'Content' : 'Component'}`,
            ['Template', contentType]
          );
        }
      } else {
        setCurrentPageBreadcrumbs(`Add`, ['Template', 'Page']);
      }
    };

    onMounted(() => {
      setBreadcrumbs();
      if (isEdit) {
        getPageById();
        getEnumerationsType(route.params.id);
      }
      if (contentEdit) {
        getContentDetailById();
      }
      getUserLevels();
      getEnumerationsList();
    });

    const rules = ref({
      displayTitle: [
        {
          required: true,
          message: 'Display Title is required',
          trigger: 'change'
				}
      ],
      sortOrder: [
        {
          required: true,
          message: 'sort order is required',
          trigger: 'change'
				}
			]
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      if (selectedPricingPlans.value.length) {
        const pricingPlansIds = selectedPricingPlans.value.map(
          item => item.pricePlanId
        );
        formData.value.pricingPlans = pricingPlansIds.join();
      }

      formRef.value.validate(valid => {
        if (valid) {
          loading.value = true;

          let templateContentFields: any = {};
          for (const formKey in formData.value) {
            if (Object.prototype.hasOwnProperty.call(formData.value, formKey)) {
              const item = formData.value[formKey];
              if (
                formKey in templateData.value &&
                typeof templateData.value[formKey] === 'boolean' &&
                templateData.value[formKey]
              ) {
                templateContentFields = {
                  ...templateContentFields,
                  [formKey]: item
                };
              }
            }
          }
          const data: any = {
            templateContentFields,
            id: route.params.contentId,
            templateId: route.params.id,
            additionalFields: setAdditionalField(),
            customerId: selectedCustomer.value,
            creatorId: formData.value.creatorId,
            contentType: templateData.value.contentType,
            contentName: templateData.value.contentName,
            userLevels: formData.value.userLevels.toString(),
            published: formData.value.published,
            public: formData.value.public,
            slug: formData.value.slug,
            enumerationId: templateData.value.enumerationId,
            enumerationValueId: formData.value.enumerationValueId,
            sortOrder: formData.value.sortOrder,
            freeContent: formData.value.freeContent,
            pricingPlans: formData.value.pricingPlans,
            publicationDate: formData.value.publicationDate ? moment(formData.value.publicationDate).format('YYYY-MM-DD HH:mm') : '',
            relatedPlayList: formData.value.relatedPlayList,
            extraVideoAssets: extraVideoAssetsIdArray.value,
            relatedContents: formData.value.relatedContents
          };
          let serviceType;
          if (isEdit) {
            if (contentEdit) {
              serviceType = ApiService.put(
                `TemplateContent/${route.params.contentId}`,
                data
              );
            } else {
              serviceType = ApiService.post(`TemplateContent`, data);
            }
          }
          // else {
          // 	delete data['id'];
          // 	serviceType = ApiService.post('Page', data);
          // }
          serviceType
            .then(({ data }) => {
              // console.log('create page response', data);
              loading.value = false;
              // redirect to main page ?
              Swal.fire({
                timer: 1500,
                text: 'Form has been successfully submitted!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok, got it!',
                customClass: {
                  confirmButton: 'btn btn-primary'
								}
              }).then(() => {
                if (!contentEdit) {
                  router.push(`/templatecontent/edit/${isEdit}/${data.id}`);
                }
                if (isEdit) {
                  formData.value = {
                    ...formData.value,
                    ...data,
                    userLevels:
                      data.userLevels.indexOf(',') != -1 ||
                      data.userLevels != ''
                        ? data.userLevels.split(',')
                        : (data.userLevels = [])
                  };
                } else {
                  (
                    document.getElementById('formRef') as HTMLFormElement
                  ).reset();
                }
              });
            })
            .catch(({ response }) => {
              console.log('create page error', response);
              Swal.fire({
                text: 'Sorry, looks like there are some errors detected, please try again.',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Ok, got it!',
                customClass: {
                  confirmButton: 'btn btn-primary'
								}
              });
            });
        } else {
          Swal.fire({
            text: 'Sorry, looks like there are some errors detected, please try again.',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn btn-primary'
						}
          });
          return false;
        }
      });
    };

    const setAdditionalField = () => {
      if (enumerationsType.value.length) {
        const addField = enumerationsType.value?.map(tab => {
          const tabItem = {
            tabName: tab?.jsonProperty,
            fields: tab?.fields.map(field => {
              if (field.fieldType === 'EnumMulti') {
                return {
                  fieldName: field.jsonProperty,
                  fieldType: field.fieldType,
                  values: fieldsData.value[field.jsonProperty].map(item => {
                    return { value: item };
                  })
                };
              }
              else if(field.fieldType === 'Events')
              {
                return {
                  fieldName: field.jsonProperty,
                  fieldType: field.fieldType,
                };
              }
              else {
                return {
                  fieldName: field.jsonProperty,
                  fieldType: field.fieldType,
                  value: fieldsData.value[field.jsonProperty]
                };
              }
            })
          };
          return tabItem;
        });
        return addField;
      }
      return [];
    };

    const getScheduleEvents = () => {
      ApiService.get(`ScheduleEvents/TemplateContent/${route.params.contentId}`)
        .then(({ data }) => {
          scheduleEvents.value = data;

          scheduleEvents.value.map(event => {
            event.startDate =  moment(convertUTCDateToLocalDate(new Date(event.startDate))).format('MM/DD/YYYY, hh:mm:ss A Z');
            event.endDate = moment(convertUTCDateToLocalDate(new Date(event.endDate))).format('MM/DD/YYYY, hh:mm:ss A Z');
          })
        })
        .catch(({ response }) => {
          console.log('create page error', response);
          Swal.fire({
            text: 'Sorry, looks like there are some errors detected, please try again.',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn btn-primary'
            }
          });
        });
    }
    
    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;   
    }

    const editScheduleEvent = (item : IScheduleEvent) => {
      console.log('edit schedule event', item)
			selectedScheduledEvent.value.id = item.id;
      selectedScheduledEvent.value.title = item.title;
      selectedScheduledEvent.value.dateRange = [item.startDate, item.endDate];
      selectedScheduledEvent.value.eventContentType = item.eventContentType;
      selectedScheduledEvent.value.elementId = item.elementId;
		};

    const deleteScheduleEvent = id => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				buttonsStyling: false,
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary'
				}
			}).then(result => {
				if (result.isConfirmed) {
					deleteScheduleEventApiCall(id);
				}
			});
		};

    const deleteScheduleEventApiCall = id => {
			ApiService.delete(`ScheduleEvents/${id}`)
				.then(({ data }) => {
					console.log('data', data);
					getEnumerationsList();
					Swal.fire({
						timer: 1500,
						text: 'Data has been successfully deleted!',
						icon: 'success',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
          scheduleEvents.value = scheduleEvents.value.filter(x => x.id != id);
				})
				.catch(({ response }) => {
					console.log('response', response);
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				});
		};

    const setExtraVideoContent = row => {
      if (!extraVideoAssetsIdArray.value.includes(row.id)) {
        extraVideoAssetsArray.value.push(row);
        extraVideoAssetsIdArray.value.push(row.id);
      }
    };

    const setSelectedVideoContent = row => {
      if (videoKey.value === 'extraVideoAssets') {
        setExtraVideoContent(row);
      } else {
        videoAssetObj.value = {
          ...videoAssetObj.value,
          [videoKey.value]: row
        };
        formData.value = {
          ...formData.value,
          [videoKey.value]: row.id
        };
      }
    };

    const resetVideoObject = key => {
      videoAssetObj.value = {
        ...videoAssetObj.value,
        [key]: null
      };
      formData.value = {
        ...formData.value,
        [key]: ''
      };
    };

    const setSelectedImage = image => {
      formData.value = {
        ...formData.value,
        [imageKey.value]: image.id
      };

      cmsListObj.value = {
        ...cmsListObj.value,
        [imageKey.value]: image.url
      };
    };

    const resetSelectedImage = key => {
      cmsListObj.value = {
        ...cmsListObj.value,
        [key]: ''
      };
      formData.value = {
        ...formData.value,
        [key]: ''
      };
    };

    const setImageKey = key => {
      imageKey.value = key;
    };

    const setVideoKey = key => {
      videoKey.value = key;
    };

    const getPricePlanList = () => {
      ApiService.get(`PricePlan`)
        .then(({ data }) => {
          // console.log('formData', data, formData.value.pricingPlans);
          const filteredArray =
            data &&
            data.filter(el =>
              formData.value.pricingPlans.split(',').some(item => {
                // console.log('loop', item, '--', el.pricePlanId);
                return item == el.pricePlanId;
              })
            );

          selectedPricingPlans.value = filteredArray;
        })
        .catch(({ response }) => {
          console.log('getPricePlanList response error:', response);
          loading.value = false;
        });
    };

    const setSelectedPricePlan = item => {
      // console.log('item:', item);
      selectedPricingPlans.value.push(item);
    };

    const resetPricingPlansObject = itemId => {
      selectedPricingPlans.value = selectedPricingPlans.value.filter(
        e => e.pricePlanId !== itemId
      );
    };

    const setSelectedPlayList = row => {
      relatedPlayListObj.value = row;
      formData.value = {
        ...formData.value,
        relatedPlayList: row.id
      };
    };

    const resetPlayListObject = () => {
      relatedPlayListObj.value = {};
      formData.value = {
        ...formData.value,
        relatedPlayList: ''
      };
    };

    const resetVideoContentObject = id => {
      extraVideoAssetsArray.value = extraVideoAssetsArray.value.filter(
        e => e.id !== id
      );
      extraVideoAssetsIdArray.value = extraVideoAssetsIdArray.value.filter(
        e => e !== id
      );
    };

    return {
      rules,
      moment,
      isEdit,
      submit,
      contentEdit,
      selectedScheduledEvent,
      scheduleEvents,
      deleteScheduleEvent,
      formRef,
      contentId,
      loading,
      formData,
      userLevel,
      imageModel,
      fieldsData,
      cmsListObj,
      setVideoKey,
      setImageKey,
      templateData,
      videoAssetObj,
      isContentPage,
      getEnumValues,
      detailImageUrl,
      content1Editor,
      content2Editor,
      content3Editor,
      content4Editor,
      enumerationsType,
      resetVideoObject,
      setSelectedImage,
      getScheduleEvents,
      descriptionEditor,
      relatedPlayListObj,
      enumerationListObj,
      relatedContentsExpanded,
      getEnumerationName,
      resetSelectedImage,
      resetPlayListObject,
      setSelectedPlayList,
      editScheduleEvent,
      selectedPricingPlans,
      setSelectedPricePlan,
      headerIntroTextEditor,
      overviewShortDescriptionEditor,
      extraVideoAssetsArray,
      resetVideoContentObject,
      setSelectedVideoContent,
      resetPricingPlansObject
    };
  }
});
